import { SET_EXCEL_DATA, SET_EXCEL_LIST_DATA } from "./action.types";
import { SET_EXCEL_FORM_DATA } from "./action.types";

export default (state, action) => {
    switch (action.type) {
        case SET_EXCEL_DATA:
            return action.payload;
        case SET_EXCEL_FORM_DATA:
            // console.log(action.payload, 'changed');
            return { ...state, formData: action.payload }
        case SET_EXCEL_LIST_DATA:
            return { ...state, listData: action.payload };
        default:
            return state;
    }
};