import React, { useContext, useState } from 'react'
import { ReportContext } from './context/ReportContext';
import { Tab } from '@headlessui/react'
import { CheckIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { ArrowLeftIcon, BackwardIcon, CheckBadgeIcon, ChevronLeftIcon, CursorArrowRaysIcon, InformationCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { ExcelContext } from './context/ExcelContext';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function ReportView() {

    const { xlsData, setXlsData } = useContext(ExcelContext);
    const { report, setReport } = useContext(ReportContext);

    const allowWhatsapp = xlsData.formData.notify.find((o) => o.id === "whatsapp").checked;
    const allowSMS = xlsData.formData.notify.find((o) => o.id === "sms").checked;
    const allowEmail = xlsData.formData.notify.find((o) => o.id === "email").checked;

    let [categories] = useState({
        Recent: [
            {
                id: 1,
                title: 'Does drinking coffee make you smarter?',
                date: '5h ago',
                commentCount: 5,
                shareCount: 2,
            },
            {
                id: 2,
                title: "So you've bought coffee... now what?",
                date: '2h ago',
                commentCount: 3,
                shareCount: 2,
            },
        ],
        Popular: [
            {
                id: 1,
                title: 'Is tech making coffee better or worse?',
                date: 'Jan 7',
                commentCount: 29,
                shareCount: 16,
            },
            {
                id: 2,
                title: 'The most innovative things happening in coffee',
                date: 'Mar 19',
                commentCount: 24,
                shareCount: 12,
            },
        ],
        Trending: [
            {
                id: 1,
                title: 'Ask Me Anything: 10 answers to your questions about coffee',
                date: '2d ago',
                commentCount: 9,
                shareCount: 5,
            },
            {
                id: 2,
                title: "The worst advice we've ever heard about coffee",
                date: '4d ago',
                commentCount: 1,
                shareCount: 2,
            },
        ],
    })

    return (
        <React.Fragment>
            <div className="w-full max-w-md px-2 py-16 sm:px-0 hidden">
                <Tab.Group>
                    <Tab.List className="flex space-x-1 rounded-xl bg-blue-900 p-1">
                        <Tab
                            key={1}
                            className={
                                classNames(
                                    'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                                    'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none',
                                    true
                                        ? 'bg-white shadow'
                                        : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                                )
                            }
                        >
                            WhatsApp
                        </Tab>
                    </Tab.List>
                    <Tab.Panels className="mt-2">
                        {Object.values(categories).map((posts, idx) => (
                            <Tab.Panel
                                key={idx}
                                className={classNames(
                                    'rounded-xl bg-white p-3',
                                    'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                                )}
                            >
                                <ul>
                                    {posts.map((post) => (
                                        <li
                                            key={post.id}
                                            className="relative rounded-md p-3 hover:bg-gray-100"
                                        >
                                            <h3 className="text-sm font-medium leading-5">
                                                {post.title}
                                            </h3>

                                            <ul className="mt-1 flex space-x-1 text-xs font-normal leading-4 text-gray-500">
                                                <li>{post.date}</li>
                                                <li>&middot;</li>
                                                <li>{post.commentCount} comments</li>
                                                <li>&middot;</li>
                                                <li>{post.shareCount} shares</li>
                                            </ul>

                                            <a
                                                href="#"
                                                className={classNames(
                                                    'absolute inset-0 rounded-md',
                                                    'ring-blue-400 focus:z-10 focus:outline-none focus:ring-2'
                                                )}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </Tab.Panel>
                        ))}
                    </Tab.Panels>
                </Tab.Group>
            </div>
            {xlsData.formData.notify.find((o) => o.id == "whatsapp").checked ?
                <div className="border-b mb-7  md:grid md:grid-cols-4 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">WhatsApp</h3>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-3 md:mt-0">

                        <div className="shadows">
                            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">

                                <div className="p-4 bg-white mb-6 transition-shadow border rounded-lg">
                                    <div className="flex items-start justify-between">
                                        <div className="flex flex-col space-y-2">
                                            <span className="text-gray-400">Total Sent</span>
                                            <span className="text-lg font-semibold">{report.whatsapp.sent}</span>
                                        </div>
                                        <div className="p-5 bg-gray-200 rounded-md flex items-center justify-center">
                                            <InformationCircleIcon width={20} className="text-blue-600" />
                                        </div>
                                    </div>
                                    <div className='hidden'>
                                        <span className="inline-block px-2 text-sm text-white bg-green-300 rounded">14%</span>
                                        <span>from 2019</span>
                                    </div>
                                </div>
                                <div className="p-4 bg-white mb-6 transition-shadow border rounded-lg">
                                    <div className="flex items-start justify-between">
                                        <div className="flex flex-col space-y-2">
                                            <span className="text-gray-400">Total Delivered</span>
                                            <span className="text-lg font-semibold">{report.whatsapp.delivered}</span>
                                        </div>
                                        <div className="p-5 bg-gray-200 rounded-md flex items-center justify-center">
                                            <CheckIcon width={20} className="text-green-600" />
                                        </div>
                                    </div>
                                    <div className='hidden'>
                                        <span className="inline-block px-2 text-sm text-white bg-green-300 rounded">14%</span>
                                        <span>from 2019</span>
                                    </div>
                                </div>
                                <div className="p-4 bg-white mb-6 transition-shadow border rounded-lg">
                                    <div className="flex items-start justify-between">
                                        <div className="flex flex-col space-y-2">
                                            <span className="text-gray-400">Total Failed</span>
                                            <span className="text-lg font-semibold">{report.whatsapp.failed}</span>
                                        </div>
                                        <div className="p-5 bg-gray-200 rounded-md flex items-center justify-center">
                                            <XMarkIcon width={20} className="text-red-600" />
                                        </div>
                                    </div>
                                    <div className='hidden'>
                                        <span className="inline-block px-2 text-sm text-white bg-green-300 rounded">14%</span>
                                        <span>from 2019</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div> : ""}


            {xlsData.formData.notify.find((o) => o.id == "email").checked ?
                <div className="border-b mb-7  md:grid md:grid-cols-4 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Email</h3>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-3 md:mt-0">

                        <div className="shadows">
                            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">

                                <div className="p-4 bg-white mb-6 transition-shadow border rounded-lg">
                                    <div className="flex items-start justify-between">
                                        <div className="flex flex-col space-y-2">
                                            <span className="text-gray-400">Total Sent</span>
                                            <span className="text-lg font-semibold">{report.email.sent}</span>
                                        </div>
                                        <div className="p-5 bg-gray-200 rounded-md flex items-center justify-center">
                                            <InformationCircleIcon width={20} className="text-blue-600" />
                                        </div>
                                    </div>
                                    <div className='hidden'>
                                        <span className="inline-block px-2 text-sm text-white bg-green-300 rounded">14%</span>
                                        <span>from 2019</span>
                                    </div>
                                </div>
                                <div className="p-4 bg-white mb-6 transition-shadow border rounded-lg">
                                    <div className="flex items-start justify-between">
                                        <div className="flex flex-col space-y-2">
                                            <span className="text-gray-400">Total Delivered</span>
                                            <span className="text-lg font-semibold">{report.email.delivered}</span>
                                        </div>
                                        <div className="p-5 bg-gray-200 rounded-md flex items-center justify-center">
                                            <CheckIcon width={20} className="text-green-600" />
                                        </div>
                                    </div>
                                    <div className='hidden'>
                                        <span className="inline-block px-2 text-sm text-white bg-green-300 rounded">14%</span>
                                        <span>from 2019</span>
                                    </div>
                                </div>
                                <div className="p-4 bg-white mb-6 transition-shadow border rounded-lg">
                                    <div className="flex items-start justify-between">
                                        <div className="flex flex-col space-y-2">
                                            <span className="text-gray-400">Total Failed</span>
                                            <span className="text-lg font-semibold">{report.email.failed}</span>
                                        </div>
                                        <div className="p-5 bg-gray-200 rounded-md flex items-center justify-center">
                                            <XMarkIcon width={20} className="text-red-600" />
                                        </div>
                                    </div>
                                    <div className='hidden'>
                                        <span className="inline-block px-2 text-sm text-white bg-green-300 rounded">14%</span>
                                        <span>from 2019</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div> : ""}


            {xlsData.formData.notify.find((o) => o.id == "sms").checked ?
                <div className="mb-7 md:grid md:grid-cols-4 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">SMS</h3>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-3 md:mt-0">

                        <div className="shadows">
                            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">

                                <div className="p-4 bg-white mb-6 transition-shadow border rounded-lg">
                                    <div className="flex items-start justify-between">
                                        <div className="flex flex-col space-y-2">
                                            <span className="text-gray-400">Total Sent</span>
                                            <span className="text-lg font-semibold">{report.sms.sent}</span>
                                        </div>
                                        <div className="p-5 bg-gray-200 rounded-md flex items-center justify-center">
                                            <InformationCircleIcon width={20} className="text-blue-600" />
                                        </div>
                                    </div>
                                    <div className='hidden'>
                                        <span className="inline-block px-2 text-sm text-white bg-green-300 rounded">14%</span>
                                        <span>from 2019</span>
                                    </div>
                                </div>
                                <div className="p-4 bg-white mb-6 transition-shadow border rounded-lg">
                                    <div className="flex items-start justify-between">
                                        <div className="flex flex-col space-y-2">
                                            <span className="text-gray-400">Total Delivered</span>
                                            <span className="text-lg font-semibold">{report.sms.delivered}</span>
                                        </div>
                                        <div className="p-5 bg-gray-200 rounded-md flex items-center justify-center">
                                            <CheckIcon width={20} className="text-green-600" />
                                        </div>
                                    </div>
                                    <div className='hidden'>
                                        <span className="inline-block px-2 text-sm text-white bg-green-300 rounded">14%</span>
                                        <span>from 2019</span>
                                    </div>
                                </div>
                                <div className="p-4 bg-white mb-6 transition-shadow border rounded-lg">
                                    <div className="flex items-start justify-between">
                                        <div className="flex flex-col space-y-2">
                                            <span className="text-gray-400">Total Failed</span>
                                            <span className="text-lg font-semibold">{report.sms.failed}</span>
                                        </div>
                                        <div className="p-5 bg-gray-200 rounded-md flex items-center justify-center">
                                            <XMarkIcon width={20} className="text-red-600" />
                                        </div>
                                    </div>
                                    <div className='hidden'>
                                        <span className="inline-block px-2 text-sm text-white bg-green-300 rounded">14%</span>
                                        <span>from 2019</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div> : ""}

            {report.show ?
                <div className="mb-5">
                    <div className="flex flex-col">
                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                <div className="h-96 overflow-y-auto">
                                    <table className="bg-white min-w-full">
                                        <thead className="border-b">
                                            <tr className='bg-slate-200'>
                                                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                                    #
                                                </th>
                                                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                                    Name
                                                </th>
                                                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                                    TYPE
                                                </th>
                                                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                                    Period
                                                </th>
                                                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                                    Phone
                                                </th>
                                                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                                    Email
                                                </th>
                                                {allowWhatsapp ?
                                                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                                        Whatsapp
                                                    </th> : ""}
                                                {allowEmail ?
                                                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                                        Email
                                                    </th> : ""}
                                                {allowSMS ?
                                                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                                        SMS
                                                    </th> : ""}

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(xlsData.formData.type.id == 7 ? xlsData.notfiled2 : xlsData.notfiled).map((v, i) =>
                                                <tr key={i} className="border-b hover:bg-slate-100">
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{i + 1}</td>
                                                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                        {v.name}
                                                    </td>
                                                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                        {xlsData.type}
                                                    </td>
                                                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                        {xlsData.period}
                                                    </td>
                                                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                        {v.phone}
                                                    </td>
                                                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                        {v.email}
                                                    </td>
                                                    {allowWhatsapp ?
                                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            {report.whatsapp.data != undefined && report.whatsapp.data[i] != undefined && report.whatsapp.data[i].sent && report.whatsapp.data[i].delivered ?
                                                                <CheckBadgeIcon className='text-green-600' width={25} /> :
                                                                <ExclamationCircleIcon className='text-red-600' width={25} />}
                                                        </td> : ""}
                                                    {allowEmail ?
                                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            {report.email.data != undefined && report.email.data[i] != undefined && report.email.data[i].sent && report.email.data[i].delivered ? <CheckBadgeIcon className='text-green-600' width={25} /> : <ExclamationCircleIcon className='text-red-600' width={25} />}
                                                        </td> : ""}
                                                    {allowSMS ?
                                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            {report.sms.data != undefined && report.sms.data[i] != undefined && report.sms.data[i].sent && report.sms.data[i].delivered ? <CheckBadgeIcon className='text-green-600' width={25} /> : <ExclamationCircleIcon className='text-red-600' width={25} />}
                                                        </td> : ""}
                                                </tr>
                                            )}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : ""}


            <button
                type="button"
                onClick={() => {
                    window.location.reload()
                }}
                className="inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 pl-2 pr-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            >
                <ChevronLeftIcon width={20} /> Home
            </button>

        </React.Fragment>
    )

}