import React, { Fragment, useCallback, useEffect, useContext, useState } from 'react'

import { toast } from 'react-toastify';

import { Switch } from '@headlessui/react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import axios from 'axios';
import { ReportContext } from './context/ReportContext';
import ExcelForm from './ExcelForm';
import { ExcelContext } from './context/ExcelContext';
import SendReminderForm from './SendReminderForm';
import ReportView from './ReportView';


export default function UploadForm() {

    const { xlsData } = useContext(ExcelContext);

    const { report } = useContext(ReportContext);

    return (
        <>
            <div>
                {
                    !report.show ?
                        <div className="md:grid md:grid-cols-3 md:gap-6">
                            <div className="md:col-span-1">
                                <div className="px-4 sm:px-0">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Excel File</h3>
                                    <p className="mt-1 text-sm text-gray-600">

                                        Reads Various type of Reminder Sheet & send personalized reminders in bulk through SMS, WhatsApp and Email to Clients.
                                    </p>
                                </div>
                            </div>
                            <div className="mt-5 md:col-span-2 md:mt-0">
                                <form action="#" method="POST">
                                    <div className="shadows">
                                        {xlsData.xlsdata.length > 0 ? <SendReminderForm /> : <ExcelForm />}
                                    </div>
                                </form>
                            </div>
                        </div> :
                        <ReportView />
                }
            </div>
        </>
    )
}
