import React, { Fragment, useCallback, useEffect, useContext, useState } from 'react'

import { toast } from 'react-toastify';

import { Switch } from '@headlessui/react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import axios from 'axios';
import { ReportContext } from './context/ReportContext';
import ExcelForm from './ExcelForm';
import { ExcelContext } from './context/ExcelContext';
import { SET_EXCEL_DATA, SET_EXCEL_FORM_DATA, SET_REPORT_DATA } from './context/action.types';


export default function SendReminderForm() {

    const [disabledSend, setDisabledSend] = useState(false);
    const { xlsData, setXlsData } = useContext(ExcelContext);

    const { report, setReport } = useContext(ReportContext);


    const notifyLoading = (msg) => toastId.current = toast.loading("Please wait...", {
        theme: "dark",
        position: toast.POSITION.TOP_CENTER,
    });

    const notfiyUpdate = (msg) => toast.update(toastId.current, { render: msg, type: "loading", isLoading: true });

    const notfiyDone = (msg) => toast.update(toastId.current, { render: msg, type: "info", isLoading: false });

    const reportCallback = useCallback((d) => {

        const allowWhatsapp = xlsData.formData.notify.find((o) => o.id === "whatsapp").checked;
        const allowSMS = xlsData.formData.notify.find((o) => o.id === "sms").checked;
        const allowEmail = xlsData.formData.notify.find((o) => o.id === "email").checked;

        let done = true;
        if (allowSMS && !d.sms.done) {
            done = false;
        }

        if (allowEmail && !d.email.done) {
            done = false;
        }

        if (allowWhatsapp && !d.whatsapp.done) {
            done = false;
        }

        // console.log("done check", d, done, allowEmail, allowSMS, allowWhatsapp);

        if (done) {
            setDisabledSend(true);
            d.show = true;
            notfiyDone("Requests sent successfully !");
            setTimeout(() => {
                toast.dismiss(toastId.current)
            }, 2000)
        } else {
            // notfiyUpdate(d.whatsapp.delivered + "/" + xlsData.to_be_reminded + " messages have been sent");
        }

        // console.log(d, done);
        setReport({
            type: SET_REPORT_DATA,
            payload: d
        });


    }, [report]);

    const toastId = React.useRef(null);



    const sendReminders = async () => {

        setDisabledSend(true);

        notifyLoading("Please Wait...");

        let r = report;

        r.whatsapp = {
            delivered: 0,
            failed: 0,
            sent: 0,
            data: [],
            to_remind: xlsData.formData.type.id == 7 ? xlsData.notfiled2.length : xlsData.notfiled.length
        }

        r.email = {
            delivered: 0,
            failed: 0,
            sent: 0,
            data: [],
            to_remind: xlsData.formData.type.id == 7 ? xlsData.notfiled2.length : xlsData.notfiled.length
        }

        r.sms = {
            delivered: 0,
            failed: 0,
            sent: 0,
            data: [],
            to_remind: xlsData.formData.type.id == 7 ? xlsData.notfiled2.length : xlsData.notfiled.length
        }

        reportCallback(r)

        const to_remind = xlsData.formData.type.id == 7 ? xlsData.notfiled2 : xlsData.notfiled;


        const allowWhatsapp = xlsData.formData.notify.find((o) => o.id === "whatsapp").checked;
        const allowSMS = xlsData.formData.notify.find((o) => o.id === "sms").checked;
        const allowEmail = xlsData.formData.notify.find((o) => o.id === "email").checked;

        const emailData = to_remind;
        const whatsappData = to_remind;
        const smsData = to_remind;

        // console.log("choosed xlsData", xlsData)


        const sendEmail = allowEmail ? await axios.post(process.env.REACT_APP_API + "/send-email", {
            type: xlsData.type,
            emailData: emailData,
            period: xlsData.period,
            email: process.env.REACT_APP_TEMPLATE_EMAIL,
            phone: process.env.REACT_APP_TEMPLATE_PHONE,
            des: process.env.REACT_APP_TEMPLATE_FROM_DES,
            name: process.env.REACT_APP_TEMPLATE_FROM_NAME,
            template: xlsData.formData.template.name == "English" ? "en" : "hi"
        }, {
            mode: 'no-cors',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        }).then((response) => {
            if (response.data.status) {
                console.log(response.data, "email sent");
                r.email = {
                    delivered: response.data.delivered,
                    failed: response.data.failed,
                    sent: response.data.sent,
                    to_remind: to_remind.length,
                    data: response.data.data,
                    done: true,
                };
            } else {
                r.email = {
                    delivered: 0,
                    failed: 0,
                    sent: 0,
                    to_remind: to_remind.length,
                    data: [],
                    done: true,
                }
            }
            reportCallback(r)

            return true;

        }).catch((error) => {

            r.email = {
                delivered: 0,
                failed: 0,
                sent: 0,
                to_remind: to_remind.length,
                data: [],
                done: true,
            }
            reportCallback(r)

            return true;

        }) : true;

        const sendWhatsapp = allowWhatsapp ? await axios.post(process.env.REACT_APP_API + "/send-whatsapp", {
            type: xlsData.type,
            caname: xlsData.caname,
            whatsappData: whatsappData,
            period: xlsData.period,
            email: process.env.REACT_APP_TEMPLATE_EMAIL,
            phone: process.env.REACT_APP_TEMPLATE_PHONE,
            des: process.env.REACT_APP_TEMPLATE_FROM_DES,
            name: process.env.REACT_APP_TEMPLATE_FROM_NAME,
            template: xlsData.formData.template.name == "English" ? "en" : "hi"
        }, {
            mode: 'no-cors',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        }).then((response) => {
            console.log(response.data, "whatsapp sent");

            if (response.data.status) {
                r.whatsapp = {
                    delivered: response.data.delivered,
                    failed: response.data.failed,
                    sent: response.data.sent,
                    to_remind: response.data.sent,
                    data: response.data.data,
                    done: true,
                }
            } else {
                r.whatsapp = {
                    delivered: 0,
                    failed: 0,
                    sent: 0,
                    to_remind: to_remind.length,
                    data: [],
                    done: true,
                }
            }
            reportCallback(r)

            return true;

        }).catch((error) => {

            console.log(error, "whatsapp sent");

            r.whatsapp = {
                delivered: 0,
                failed: 0,
                sent: 0,
                to_remind: to_remind.length,
                data: [],
                done: true,
            }
            reportCallback(r)

        }) : true;


        const sendSMS = (allowSMS) ? await axios.post(process.env.REACT_APP_API + "/send-sms", {
            type: xlsData.type,
            smsData: smsData,
            period: xlsData.period,
            email: process.env.REACT_APP_TEMPLATE_EMAIL,
            phone: process.env.REACT_APP_TEMPLATE_PHONE,
            des: process.env.REACT_APP_TEMPLATE_FROM_DES,
            name: process.env.REACT_APP_TEMPLATE_FROM_NAME,
            template: xlsData.formData.template.name == "English" ? "en" : "hi"
        }, {
            mode: 'no-cors',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        }).then((response) => {
            if (response.data.status) {
                console.log(response.data, "sms sent");
                r.sms = {
                    delivered: response.data.delivered,
                    failed: response.data.failed,
                    sent: response.data.sent,
                    to_remind: response.data.sent,
                    data: response.data.data,
                    done: true,
                }
                reportCallback(r)
            } else {
                reportCallback({
                    ...r, sms: {
                        delivered: 0,
                        failed: 0,
                        sent: 0,
                        data: [],
                        to_remind: to_remind.length,
                        done: true,
                    }
                })
            }

            return true;
        }).catch((error) => {

            reportCallback({
                ...r, sms: {
                    delivered: 0,
                    failed: 0,
                    sent: 0,
                    to_remind: to_remind.length,
                    data: [],
                    done: true,
                }
            })

            return true;

        }) : true;
    }

    return (
        <div className="bg-gray-50 px-4 py-3 text-left sm:px-6">
            <h3 className="my-2">Reminders</h3>

            <table className="min-w-full">
                <tbody>
                    <tr>
                        <th className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">FIELD</th>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">

                            <FormTemplateSelect />
                        </td>
                    </tr>
                    <tr className="border-b">
                        <th className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Reminder Type</th>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {xlsData.type}
                        </td>
                    </tr>
                    <tr className="border-b">
                        <th className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Period</th>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {xlsData.period}
                        </td>
                    </tr>
                    <tr className="border-b">
                        <th className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Total Entries</th>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {xlsData.total}
                        </td>
                    </tr>
                    <tr className="border-b">
                        <th className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Filed Entries</th>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {xlsData.total - xlsData.to_be_reminded}
                        </td>
                    </tr>
                    <tr className="border-b">
                        <th className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Pending Entries</th>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {xlsData.to_be_reminded}
                        </td>
                    </tr>
                    <tr className="border-b">
                        <th className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Notify</th>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {xlsData.formData.notify.map((v, i) => {

                                return <MyToggle xlsData={xlsData} setXlsData={setXlsData} key={i} checked={v.checked} name={v.id} label={v.name} />
                            })}
                        </td>
                    </tr>
                    <tr>
                        <th className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">FIELD</th>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">

                            <FormSelBox xlsData={xlsData} setXlsData={setXlsData} />
                        </td>
                    </tr>
                </tbody>
            </table>



            <div className='flex justify-between mt-5'>
                {xlsData.to_be_reminded > 0 && !disabledSend ?
                    <button
                        type="button"
                        onClick={sendReminders}
                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                        Send Reminders ({xlsData.to_be_reminded})
                    </button>
                    : <button
                        type="button"
                        disabled
                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-400"
                    >
                        Send Reminders ({xlsData.to_be_reminded})
                    </button>}
                <button
                    type="button"
                    onClick={() => window.location.reload()}
                    className="inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                >
                    Back
                </button>
            </div>
        </div>)
}


function MyToggle({ xlsData, setXlsData, label, name, checked }) {

    const onChange = (i) => {
        let notify = [...xlsData.formData.notify];

        notify.find(o => o.id === name).checked = i;

        setXlsData({
            type: SET_EXCEL_FORM_DATA,
            payload: {
                ...xlsData.formData, notify: notify
            }
        })
    }

    return (

        <Switch.Group>
            <Switch
                checked={checked}
                onChange={onChange}
                label={label}
                className={`${checked ? 'bg-blue-600' : 'bg-gray-200'
                    } relative inline-flex h-5 w-10 items-center rounded-full`}
            >

                <span className="sr-only">{label}</span>
                <span
                    className={`${checked ? 'translate-x-6' : 'translate-x-1'
                        } inline-block h-3 w-3 transform rounded-full bg-white transition`}
                />
            </Switch>
            <Switch.Label className={"ml-1 mr-3"}>{label}</Switch.Label>
        </Switch.Group>
    )
}


function FormSelBox() {

    const { xlsData, setXlsData } = useContext(ExcelContext);
    const [selected, setSelected] = useState(xlsData.formData.type)

    useEffect(() => {

        let xlsDataD = { ...xlsData };
        xlsDataD.formData.type = selected
        xlsDataD.to_be_reminded = selected.id == 7 ? xlsData.notfiled2.length : xlsData.notfiled.length

        setXlsData({
            type: SET_EXCEL_DATA,
            payload: xlsDataD
        })

    }, [selected])

    return (
        <div className="w-72 c z-10">
            <Listbox value={xlsData.formData.type} onChange={setSelected}>
                <div className="relative mt-1">
                    <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                        <span className="block truncate">{xlsData.formData.type.name}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </span>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options className="z-30 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {xlsData.listData.map((person, personIdx) => (
                                <Listbox.Option
                                    key={personIdx}
                                    className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                                        }`
                                    }
                                    value={person}
                                >
                                    {({ selected }) => (
                                        <>
                                            <span
                                                className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                    }`}
                                            >
                                                {person.name}
                                            </span>
                                            {selected ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </div>
    )
}



function FormTemplateSelect() {

    const { xlsData, setXlsData } = useContext(ExcelContext);
    const [selected, setSelected] = useState(xlsData.formData.template)

    useEffect(() => {

        let xlsDataD = { ...xlsData };
        xlsDataD.formData.template = selected

        setXlsData({
            type: SET_EXCEL_DATA,
            payload: xlsDataD
        })

    }, [selected])

    return (
        <div className="w-72 c z-10">
            <Listbox value={selected} onChange={setSelected}>
                <div className="relative mt-1">
                    <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                        <span className="block truncate">{selected.name}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </span>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options className="z-30 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {xlsData.templateOptions.map((person, personIdx) => (
                                <Listbox.Option
                                    key={personIdx}
                                    className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                                        }`
                                    }
                                    value={person}
                                >
                                    {({ selected }) => (
                                        <>
                                            <span
                                                className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                    }`}
                                            >
                                                {person.name}
                                            </span>
                                            {selected ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </div>
    )
}

