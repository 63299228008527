import Header from "./layout/Header";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React from "react";
function PrivacyPolicy() {


    return (
        <div className="App">
            <div className="min-h-full">
                <Header heading="Privacy Policy" />
                <main>
                    <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
                        {/* Replace with your content */}
                        <div className="px-4 py-6 sm:px-0">

                            <div className="bg-white p-5">
                                <p>We respect the privacy of those who have or had business with us. In accordance with this, we have created this policy in order to demonstrate our privacy commitment to our clients :
                                    What this Privacy Policy Covers :
                                    This Privacy Policy covers our treatment of personally identifiable
                                    information that we collect or submitted to us whether offline
                                    or online, when you use our services. Information Use :
                                    We use this information for two general purposes : to fulfill your requests for certain financial and taxation services, and to contact you about services.
                                    Information Sharing and Disclosure :
                                    We will not sell or rent your personally identifiable information to anyone.
                                    We will send personally identifiable information about you to other companies or people when
                                    We have your consent to share the information;
                                    We respond to subpoenas, court orders or legal process;
                                    Changes to this Privacy Policy :
                                    The privacy policy is subject to modification from time to time. If we decide to change our privacy policy, we will post those changes here so that you will always know what information we gather, how we might use that information and whether we will disclose it to anyone.</p>
                            </div>

                        </div>
                        {/* /End replace */}
                    </div>
                </main>
            </div>
            <ToastContainer
                position="top-right"
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            {/* Same as */}
            <ToastContainer />

        </div>
    );
}

export default PrivacyPolicy;
