import logo from './logo.svg';

import Header from "./layout/Header";

import UploadForm from './UploadForm';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReportContext } from "./context/ReportContext";
import ReportReducer from './context/ReportReducer';

import React, { useReducer } from "react";
import { ExcelContext } from './context/ExcelContext';
import ExcelReducer from './context/ExcelReducer';
import Footer from './layout/Footer';

function App() {

  const [report, setReport] = useReducer(ReportReducer, {
    whatsapp: {
      delivered: 0,
      failed: 0,
      sent: 0,
      to_remind: 0
    },
    email: {
      delivered: 0,
      failed: 0,
      sent: 0,
      to_remind: 0
    },
    sms: {
      delivered: 0,
      failed: 0,
      sent: 0,
      to_remind: 0
    }
  });

  const templateOptions = [{ id: 0, name: "English" }, { id: 1, name: "Hindi" }];

  const [xlsData, setXlsData] = useReducer(ExcelReducer, {
    type: "",
    period: "",
    total: 0,
    to_be_reminded: 0,
    xlsdata: [],
    filed: [],
    notfiled: [],
    filed2: [],
    notfiled2: [],
    listData: [{ id: 1, name: "Select Type", unavailable: false }],
    templateOptions: templateOptions,
    formData: {
      notify: [
        { id: 'whatsapp', name: "Whatsapp", checked: true },
        { id: 'email', name: "Email", checked: true },
        { id: 'sms', name: "SMS", checked: true },
      ],
      type: 0,
      template: templateOptions[0],
    }
  });

  return (
    <ReportContext.Provider value={{ report, setReport }}>
      <ExcelContext.Provider value={{ xlsData, setXlsData }}>
        <div className="App">
          <div className="min-h-screen">
            <Header heading={"Personalized Reminder"} />
            <main>
              <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
                {/* Replace with your content */}
                <div className="px-4 py-6 sm:px-0">

                  <UploadForm />
                </div>
                {/* /End replace */}
              </div>
            </main>
          </div>

          <ToastContainer
            position="top-right"
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          {/* Same as */}
          <ToastContainer />
          <Footer />

        </div>

      </ExcelContext.Provider>
    </ReportContext.Provider>
  );
}

export default App;
