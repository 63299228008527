import React, { Fragment, useCallback, useEffect, useContext, useState } from 'react'

import { useDropzone } from 'react-dropzone'

import * as XLSX from 'xlsx/xlsx.mjs';
import Moment from 'moment';
import { toast } from 'react-toastify';
import { SET_EXCEL_DATA, SET_EXCEL_FORM_DATA, SET_EXCEL_LIST_DATA } from './context/action.types';
import { ExcelContext } from './context/ExcelContext';

export default function ExcelForm() {

    const { xlsData, setXlsData } = useContext(ExcelContext);

    // const [listData, setListData] = useState([{ id: 0, name: "Select Type", unavailable: false }])


    const [status, setStatus] = useState('');

    const onDrop = useCallback(async (acceptedFiles) => {

        setStatus('loading');

        await acceptedFiles.forEach(async (file) => {

            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                try {

                    /* Parse data */
                    const bstr = reader.result;
                    const wb = XLSX.read(bstr, { type: 'binary' });
                    /* Get first worksheet */
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];

                    // const data = XLSX.utils.sheet_to_html(ws, { header: 1 });
                    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

                    console.log("checked", data);

                    const templateOptions = [{ id: 0, name: "English" }, { id: 1, name: "Hindi" }];

                    let res = {
                        type: "",
                        period: "",
                        caname: "",
                        total: 0,
                        to_be_reminded: 0,
                        xlsdata: [],
                        filed: [],
                        notfiled: [],
                        filed2: [],
                        notfiled2: [],
                        listData: [{ id: 1, name: "Select Type", unavailable: false }],
                        templateOptions: templateOptions,
                        formData: {
                            notify: [
                                { id: 'whatsapp', name: "Whatsapp", checked: true },
                                { id: 'email', name: "Email", checked: true },
                                { id: 'sms', name: "SMS", checked: true },
                            ],
                            type: 0,
                            template: templateOptions[0],
                        }
                    };


                    Promise.all(data.map((v, i) => {

                        if (i == 0) {
                            res.type = v[0].split("->")[0];
                            res.period = v[0].split("->")[1];
                            res.caname = v[0].split("->")[2].trim();
                            //console.log("CA Name "+res.caname);
                        }
                        if (i == 1) {

                            res.columns = v;

                            let d = [];
                            res.columns.forEach((v, i) => {
                                if (i > 4)
                                    d.push({ id: i, name: v, unavailable: false });
                            })

                            res.listData = d;

                        }

                        if (i >= 3 && v.length >= 1) {

                            let d = {
                                srno: v[0] != undefined ? v[0].toString().trim() : "",
                                name: v[1] != undefined ? v[1].toString().trim() : "",
                                gst: v[2] != undefined ? v[2].toString().trim() : "",
                                email: v[3] != undefined ? v[3].toString().trim() : "",
                                phone: v[4] != undefined && v[4].length > 10 ? v[4].substr(-10) : v[4],
                                rcd_date: v[5] != undefined ? Moment(v[5]) : "",
                                filed: v[6] != undefined,
                                //filed: v[6] != undefined && v[6].toString().trim().toUpperCase() == 'YES',
                                rcd_date2: v[7] != undefined ? Moment(v[7]) : "",
                                filed2: v[8] != undefined,
                                //filed2: v[8] != undefined && v[8].toString().trim().toUpperCase() == 'YES',
                            };

                            res.xlsdata.push(d);


                            if (!d.filed) {
                                // console.log('uploaded not filed', d, d.filed);
                                res.notfiled.push(d);
                            } else {
                                // console.log('uploaded filed', d, d.filed);
                                res.filed.push(d);
                            }

                            if (!d.filed2) {
                                res.notfiled2.push(d);
                            } else {
                                res.filed2.push(d);
                            }
                        }


                    })).then(() => {
                        // console.log(res);
                        res.total = res.xlsdata.length;
                        res.to_be_reminded = res.notfiled.length;

                        res.formData = xlsData.formData;
                        res.formData.type = res.listData[0];

                        const templateOptions = [{ id: 0, name: "English" }, { id: 1, name: "Hindi" }];
                        res.templateOptions = templateOptions;
                        res.template = templateOptions[0];

                        setXlsData({
                            type: SET_EXCEL_DATA,
                            payload: res
                        });


                    }).catch((e) => console.log(e))



                } catch (e) {
                    toast.error("Something went wrong !", {
                        theme: "dark"
                    });
                    console.error(e)
                    setStatus('failed');

                }
            }

            await reader.readAsArrayBuffer(file);


        });


    }, [])

    const { getRootProps, getInputProps, open, isDragActive } = useDropzone({ onDrop })




    return (
        <React.Fragment>
            <div className="space-y-6 bg-white px-4 py-5 sm:p-6">

                {status == 'loading' ? <div className="bg-blue-100 rounded-lg py-5 px-6 mb-4 text-base text-blue-700 mb-3" role="alert">
                    Please wait while processing...
                </div> : ''}

                {status == 'done' ? <div className="bg-blue-100 rounded-lg py-5 px-6 mb-4 text-base text-blue-700 mb-3" role="alert">
                    Processed
                </div> : ''}

                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {

                        <div>
                            <label className="block text-sm font-medium text-gray-700">Excel Sheet</label>
                            <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                                <div className="space-y-1 text-center">
                                    <svg
                                        className="mx-auto h-12 w-12 text-gray-400"
                                        stroke="currentColor"
                                        fill="none"
                                        viewBox="0 0 48 48"
                                        aria-hidden="true"
                                    >
                                        <path
                                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    <div className="flex text-sm text-gray-600">
                                        <label
                                            htmlFor="file-upload"
                                            className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                                        >
                                            <span>Upload a file</span>
                                        </label>
                                        <p className="pl-1">or drag and drop</p>
                                    </div>
                                    <p className="text-xs text-gray-500">XLS, XLSX, CSV up to 10MB</p>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 text-left sm:px-6">
                <button
                    type="button"
                    onClick={open}
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                    Choose File
                </button>
            </div>
        </React.Fragment>
    )
}